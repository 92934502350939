import React, { useState, useEffect } from "react";
import {
  CreditCard,
  Car,
  MapPin,
  Plane,
  Hotel,
  Bus,
  Train,
  Star,
  Phone,
  Mail,
} from "lucide-react";
import { SITEDATA } from "../localData";
import { Link } from "react-router-dom";

const services = [
  {
    icon: <CreditCard className="h-8 w-8 mb-2 text-blue-600" />,
    title: "Visa & Passport",
    shortDescription:
      "Hassle-free visa and passport services for your travel needs.",
    fullDescription:
      "Our expert team assists you with visa applications and passport renewals, ensuring a smooth process for your international travel.",
    specifications: [
      "Visa application assistance for multiple countries",
      "Passport renewal and expedited services",
      "Document verification and preparation",
      "Personalized guidance on visa requirements",
      "Updates on changing visa policies and regulations",
    ],
  },
  {
    icon: <Car className="h-8 w-8 mb-2 text-blue-600" />,
    title: "Vehicle Rental",
    shortDescription:
      "Wide range of vehicles available for rent during your trip.",
    fullDescription:
      "Choose from our diverse fleet of well-maintained vehicles to enhance your travel experience, whether for business or leisure.",
    specifications: [
      "Various vehicle types: economy, luxury, SUVs, and vans",
      "Flexible rental periods: hourly, daily, weekly, and monthly options",
      "24/7 roadside assistance",
      "Optional GPS navigation systems",
      "Child safety seats available upon request",
    ],
  },
  {
    icon: <MapPin className="h-8 w-8 mb-2 text-blue-600" />,
    title: "Pick-Up & Drop Service",
    shortDescription:
      "Reliable transportation to and from airports and railway stations.",
    fullDescription:
      "Enjoy stress-free transfers with our punctual and professional pick-up and drop-off services at airports and railway stations.",
    specifications: [
      "24/7 service availability",
      "Professional, licensed drivers",
      "Flight/train tracking to adjust for delays",
      "Meet and greet service at arrivals",
      "Comfortable, air-conditioned vehicles",
    ],
  },
  {
    icon: <Plane className="h-8 w-8 mb-2 text-blue-600" />,
    title: "Flight Bookings",
    shortDescription: "Find and book the best flights for your journey.",
    fullDescription:
      "Access a wide range of flight options from various airlines, with competitive prices and flexible booking options.",
    specifications: [
      "Domestic and international flight bookings",
      "Real-time price comparisons across airlines",
      "Seat selection and meal preference options",
      "24/7 customer support for booking assistance",
      "Loyalty program integration for points accumulation",
    ],
  },
  {
    icon: <Hotel className="h-8 w-8 mb-2 text-blue-600" />,
    title: "Hotel Bookings",
    shortDescription: "Secure comfortable accommodations for your stay.",
    fullDescription:
      "Browse and book from a curated selection of hotels, resorts, and apartments to suit every budget and preference.",
    specifications: [
      "Wide range of accommodations: from budget to luxury",
      "Detailed property information and photos",
      "Guest reviews and ratings",
      "Special deals and promotional rates",
      "Flexible cancellation policies (varies by property)",
    ],
  },
  {
    icon: <Bus className="h-8 w-8 mb-2 text-blue-600" />,
    title: "Bus Bookings",
    shortDescription: "Easy booking for bus travel options.",
    fullDescription:
      "Convenient bus ticket bookings for intercity and interstate travel, with a variety of operators and routes.",
    specifications: [
      "Extensive network of bus routes",
      "Multiple operators and bus types (standard, deluxe, sleeper)",
      "Real-time seat availability",
      "M-ticket (mobile ticket) options",
      "Cancellation and rescheduling facilities",
    ],
  },
  {
    icon: <Train className="h-8 w-8 mb-2 text-blue-600" />,
    title: "Railway Bookings",
    shortDescription: "Secure your train tickets with ease.",
    fullDescription:
      "Book train tickets for local and long-distance journeys, with access to various classes and train types.",
    specifications: [
      "Bookings for all train types (local, express, high-speed)",
      "Multiple class options (economy, business, first class)",
      "Real-time availability checking",
      "Seat/berth allocation services",
      "Catering and special assistance bookings",
    ],
  },
];

const testimonials = [
  {
    name: "Ruchita Konarde",
    comment: "I recently had an amazing experience with Aarohan Holidays. Their attention to detail, seamless planning and friendly staff made my trip unforgettable",
  },
  {
    name: "Anurag Pande",
    comment: "We had an extremely memorable trip. Thanks to Kiran for listening to our places of visit and helping us to plan this trip.",
  },
  {
    name: "pooja ik",
    comment: "It was really nice experience with aarohan holidays 💖 thanks for the spiritual blessings and great memories 💞",
  },
];

export default function ServicesSection() {
  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    if (openModal !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 py-32 bg-gray-100">
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
            Our Services
          </h2>
          <p className="max-w-[900px] text-gray-600 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            We offer a comprehensive range of travel services to make your
            journey smooth and enjoyable.
          </p>
        </div>

        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-16">
          {services.map((service, index) => (
            <div key={index}>
              <div
                className="flex flex-col justify-stretch items-center text-center p-6 bg-white rounded-lg shadow-md cursor-pointer hover:bg-gray-50 transition-colors"
                onClick={() => setOpenModal(index)}
              >
                <div className="mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.shortDescription}</p>
              </div>
              {openModal === index && (
                <div className="fixed inset-0 bg-black bg-opacity-75 shadow-2xl flex items-center justify-center p-4 z-50">
                  <div className="bg-white rounded-lg p-6 max-w-md w-full max-h-[90vh] overflow-y-auto">
                    <div className="flex justify-between items-start mb-4">
                      <div className="flex items-center">
                        {service.icon}
                        <h3 className="text-xl font-semibold ml-2">
                          {service.title}
                        </h3>
                      </div>
                      <button
                        onClick={() => setOpenModal(null)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <p className="text-gray-600 mb-4">
                      {service.fullDescription}
                    </p>
                    <div>
                      <h4 className="font-semibold mb-2">Specifications:</h4>
                      <ul className="list-disc pl-5 space-y-1 text-gray-600">
                        {service.specifications.map((spec, i) => (
                          <li key={i}>{spec}</li>
                        ))}
                      </ul>
                    </div>

                    <Link to={SITEDATA.socials[0].link} target="_blank">
                      {" "}
                      <button className="w-full mt-6 bg-[var(--primary-color2)] text-white py-2 px-4 rounded transition-colors">
                        {" "}
                        Send Enquiry Now{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* New Testimonials Section */}
        <div className="mb-16">
          <h3 className="text-2xl font-bold text-center mb-8">
            What Our Customers Say
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center mb-4">
                  <Star className="text-yellow-400 w-5 h-5" />
                  <Star className="text-yellow-400 w-5 h-5" />
                  <Star className="text-yellow-400 w-5 h-5" />
                  <Star className="text-yellow-400 w-5 h-5" />
                  <Star className="text-yellow-400 w-5 h-5" />
                </div>
                <p className="text-gray-600 mb-4">"{testimonial.comment}"</p>
                <p className="font-semibold">{testimonial.name}</p>
              </div>
            ))}
          </div>
        </div>

        {/* New Contact Section */}
        <div className="text-center">
          <h3 className="text-2xl font-bold mb-4">Contact Us</h3>
          <p className="text-gray-600 mb-6">
            Have questions? Our travel experts are here to help!
          </p>
          <div className="flex justify-center space-x-4 flex-wrap ">
            <a
              href={`tel:${SITEDATA.Phone[0]}`}
              className="flex items-center text-blue-600 hover:text-blue-800"
            >
              <Phone className="w-5 h-5 mr-2" />
              {SITEDATA.Phone[0]}
            </a>
            <a
              href={`mailto:${SITEDATA.Email}`}
              className="flex items-center text-blue-600 hover:text-blue-800"
            >
              <Mail className="w-5 h-5 mr-2" />
              {SITEDATA.Email}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from "react";

function DestinationFilter({ data, filtered, setfiltered, travel }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStateSelection = (event, state) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setfiltered((prevFiltered) => ({
        ...prevFiltered,
        destination: [...prevFiltered.destination, state],
      }));
    } else {
      setfiltered((prevFiltered) => ({
        ...prevFiltered,
        destination: prevFiltered.destination.filter(
          (item) => item !== state
        ),
      }));
    }
  };

  const handleCountrySelection = (event, country) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setfiltered((prevFiltered) => ({
        ...prevFiltered,
        country: [...prevFiltered.country, country],
      }));
    } else {
      setfiltered((prevFiltered) => ({
        ...prevFiltered,
        country: prevFiltered.country.filter((item) => item !== country),
      }));
    }
  };

  // Effect to reset selections when travel changes to "domestic"
  useEffect(() => {
    if (travel === "domestic") {
      setfiltered({ destination: [], country: [] });
    }
  }, [travel, setfiltered]);

  let filteredDestinations = [];
  
  if (travel === "domestic") {
    filteredDestinations = data?.states?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];
  } else {
    filteredDestinations = data?.countrys?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];
  }

  // Helper function to get package count for a state or country
 

  return (
    <div>
      <input
        type="text"
        placeholder="Search.."
        id="myInput"
        className="w-full p-2 border border-gray-300 mb-2 outline-none"
        value={searchQuery}
        onChange={handleSearchChange}
      />

      {travel === "domestic" ? (
        // Show states only for domestic travel
        filteredDestinations.map((item, index) => (
          <React.Fragment key={index}>
            <input
              type="checkbox"
              id={"destination_id_" + item.name}
              name="destination"
              value={item.name}
              className="mx-2"
              onChange={(event) => handleStateSelection(event, item.name)}
              checked={filtered.destination.includes(item.name)}
            />
            <label
              htmlFor={"destination_id_" + item.name}
              className="capitalize "
            >
              {item.name} ({item.package_count})
            </label>
            <br />
          </React.Fragment>
        ))
      ) : (
        // Show countries only for international travel
        filteredDestinations.map((item, index) => {
          // Hide the checkbox if the country is India
          if (item.name === "India") return null;

          return (
            <React.Fragment key={index}>
              <input
                type="checkbox"
                id={"country_id_" + item.name}
                name="country"
                value={item.name}
                className="mx-2"
                onChange={(event) => handleCountrySelection(event, item.name)}
                checked={filtered.country.includes(item.name)}
              />
              <label
                htmlFor={"country_id_" + item.name}
                className="capitalize"
              >
                {item.name} ({item.package_count})
              </label>
              <br />
            </React.Fragment>
          );
        })
      )}
    </div>
  );
}

export default DestinationFilter;

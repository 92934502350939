import "./App.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Gallery from "./pages/Gallary";
import Pakages from "./pages/Pakages";
import PlanYourself from "./pages/PlanYourself";
import Search from "./pages/Search";
import PageNotFound from "./pages/PageNotFound";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import PakageDetails from "./pages/PakageDetails";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Sitemap from "./pages/Sitemap";
import BookingSection from "./components/BookingSection";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Whatsapp from "./components/Whatsapp";
import Carrer from "./pages/Carrer";
import OurServices from "./pages/OurServices";
const queryClient = new QueryClient();
function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <Navbar Headroom={true} />
        <Routes>
          <Route index element={<Home />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="pakages" element={<Pakages />} />
          <Route path="services" element={<OurServices />} />
          <Route
            path="pakages/categories/:categories"
            element={<Search type={"categories"} />}
          />
          <Route
            path="pakages/locations/:location"
            element={<Search type={"location"} />}
          />
          <Route path="pakage/detail/:slug" element={<PakageDetails />} />
          <Route path="pakage/booking/:slug" element={<BookingSection />} />

          <Route path="plan" element={<PlanYourself />} />
          <Route path="search/:query" element={<Search type={"search"} />} />
          <Route path="contact" element={<Contact />} />
          <Route path="sitemap" element={<Sitemap />} />
          <Route path="Terms_Conditions" element={<TermsAndConditions />} />
          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          <Route path="carrer" element={<Carrer />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Whatsapp />
        <Footer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
